/* ContactUs.module.css */
.footerGetTouchOuter {
  padding-top: 8rem;
}

.container {
  width: 75vw;
  max-width: 1800px;
  margin: auto;
}

.dispFlex {
  display: flex;
}

.getFormInner {
  display: block;
  padding: 50px 40px;
  background: #fff;
  box-shadow: -4px -2px 20px -7px #cfd5df;
  width: 65%;
}

.getFormInnerText h3 {
  color: #333;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
}

.grid5050 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.gridFull {
  margin: 20px 0;
}

.contactUsInput {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  height: 42px;
  padding: 0 0 0 20px;
  width: 100%;
  outline: 0;
}

textarea {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  padding: 12px 0 0 20px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
}

.input {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid;
  font-weight: 500;
  padding: 10px 20px;
  outline: 0;
  cursor: pointer;
  color: #103e65;
  transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.input:hover {
  background-color: var(--color-golden-2);
  border-color: var(--color-golden);
  color: #fff;
}

.getSayForm {
  display: inline-block;
  padding: 3rem 0 2.5rem 3rem;
  background: #807d47;
  position: relative;
  width: 35%;
}

.getSayForm h5 {
  color: #fff;
  font-size: 26px;
  margin: 0 0 40px;
}

.getSayInfoLinks i {
  color: #fff;
  font-size: 32px;
  display: flex;
}

.getSayInfoLinks {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 0.75rem;
}

.getSayInfoLink {
  width: 100%;
  display: block;
  padding: 1rem 0.75rem;
  color: #fff;
  font-size: 16px;
  text-decoration: unset;
  font-weight: 500;
  background: #6c6c4e;
  border-radius: 5px 0 0 5px;
  transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.getSayInfoLink:hover {
  background-color: #dcca87ba;
}

.getSaySocialIcn {
  display: flex;
  position: absolute;
  bottom: 40px;
}

.getSaySocialIcn li {
  font-size: 22px;
  /* color: #fff; */
  padding: 0 20px 0 0;
}

@media screen and (max-width: 1024px) {
  .dispFlex {
    flex-direction: column;
  }
  .getFormInner {
    width: 100%;
  }
  .getSayForm {
    width: 100%;
    padding: 2rem;
  }

  .getSayForm h5 {
    font-size: 1.5rem;
    text-align: center;
    margin: 0.5rem 0 1rem;
  }

  .getSayInfoLink {
    border-radius: 0.25rem;
  }

  .getSaySocialIcn {
    bottom: 5%;
    left: 50%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 90vw;
  }
  .getFormInner {
    padding: 20px;
  }
  .getSayForm {
    padding: 1.5rem;
  }
  .getSaySocialIcn {
    bottom: 5%;
    left: 46%;
  }
}

@media screen and (max-width: 480px) {
  .footerGetTouchOuter {
    padding-top: 3rem;
  }
  .container {
    width: 90vw;
  }

}

