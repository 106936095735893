.ourHotels {
  display: flex;
  width: 100%;
  margin: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  margin-top: 8rem;

}

.ourHotels h2{
  font-size: 2rem;
  font-weight: 500;
  color: var(--font-color);
  margin-bottom: 2rem;
}



.cardWrapper{
  flex: 1 1;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  position: relative;
}

.cardContainer {
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  margin-top: 1rem;
  flex-direction: row;
  width: max-content;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bookingModal {
  position: fixed;
  top: 7rem;
  right: -31rem;
  width: 31rem; 
  transition: right 0.3s ease-in-out;
  z-index: 100;
  cursor: auto;
}

.bookingModalShoow {
  right: 0; 
}

@media screen and (max-width: 560px) {
  .ourHotels {
    margin-top: 1rem;
  }
  .ourHotels h2 {
    font-size: 1.75rem;
    margin-bottom: 0;
  }
  .bookingModal {
    position: fixed;
    top: 4rem;
    width: 100%;
    height: 100%;
  }
  .bookingModalShow {
    right: 0; 
  }
}