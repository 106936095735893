.card-wrap {
  min-width: 17rem;
  background: #fff;
  border-radius: 20px;
  border: 5px solid #fff;
  overflow: hidden;
  color: var(--color-text);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transition: all 0.2s ease-in-out;
}
.card-wrap:hover {
  transform: scale(1.04);
  .card-header {
    border-radius: 100% 0% 100% 0% / 0% 950% 100% 100%;
  }
}
.card-header {
  cursor: pointer;
  height: 200px;
  width: 100%;
  border-radius: 100% 0% 100% 0% / 0% 36% 100% 100%;
  transition: all 0.5s ease-in-out;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-header img {
  width: 80%;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-height: 8.5rem;
  margin: 0 auto;
}
.card-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
}
.card-text {
  font-size: 12px;
  margin-bottom: 20px;
}
.card-btn {
  border: 1px solid black;
  border-radius: 100px;
  padding: 5px 30px;
  color: black;
  margin-bottom: 15px;
  text-transform: uppercase;
  background: linear-gradient(
    270deg,
    rgba(223, 190, 106, 0.8),
    rgba(146, 111, 52, 0.8),
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0)
  );
  background-size: 300% 300%;
  background-position: 1% 50%;
  transition: all 0.5s ease-in-out;
}

.card-btn:hover {
  background-position: 100% 50%;
}

