.bookingForm {
  margin: 1rem;
  padding: 1.5rem;
  max-width: 31rem;
  border: 2px dashed var(--color-golden);
  background-color: #1a1e22;
}
.closeBtn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
.closeBtn svg{
  width: 90%;
}

.closeBtn:hover svg{
stroke: #ffffffa2 ;
}

.callText {
  margin: 0.5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.callText a {
  color: var(--color-golden);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 6rem;
}
