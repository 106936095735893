.Home_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: auto;
}
.Hero_Section {
  width: 90%;
}
@media screen and (max-width: 532px) {

}