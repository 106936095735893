@keyframes activeIn {
    0% {
      transform: translate3d(100%, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes previousOut {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-30%, 0, 0);
    }
  }
  
  @keyframes activeInReverse {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
  
  @keyframes previousOutReverse {
    0% {
      transform: translate3d(-25%, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .carousel {
    position: relative;
    height: 90vh;
    width: 100%;
    min-width: 100%;
    overflow: hidden;
  }
  
  .carousel-controls {
    .previous,
    .next {
      z-index: 3;
      position: absolute;
      display: block;
      top: 50%;
      margin-top: -2rem;
      font-size: 4rem;
      background: none;
      border: 0;
      color: rgba(255, 255, 255, 0.198);
    }
  
    .previous {
      left: 2rem;
    }
  
    .next {
      right: 2rem;
    }

    .previous:hover,
    .next:hover {
      color: rgba(255, 255, 255, 0.765);
    }
  }

  
  
  .carousel-slide {
    z-index: -1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    
    transform: translate3d(100%, 0, 0);
    
    background-size: cover;
    background-position: center;
  
    &.active {
      z-index: 3;
      transition: transform 1.5s ease;
      transform: translate3d(0, 0, 0);
      animation: activeIn 1.5s ease;
    }
  
    &.previous {
      z-index: 2;
      transition: transform 1.5s ease;
      animation: previousOut 1.5s ease;
    }
  }
  
  .reverse .carousel-slide {
    &.active {
      z-index: 2;
      transition: transform 1.25s ease;
      transform: translate3d(0, 0, 0);
      animation: previousOutReverse 1.25s ease;
    }
  
    &.previous {
      z-index: 3;
      transition: transform 1.25s ease;
      animation: activeInReverse 1.25s ease;
    }
  }
  
  @media screen and (max-width: 532px) {
    .carousel{
      height: 50vh;
      margin-top: 2rem;
    }
    .carousel-controls {
      .previous,
      .next {
        font-size: 3rem;
        font-weight: 100;
      }
    }
  }