@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;700&display=swap");

@font-face {
  font-family: "Reddit sans";
  src: url("./assets/fonts/RedditSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/RedditSans-SemiBold.ttf") format("trueType");
  font-style: normal;
  font-display: swap;
}

:root {
  --font-base: "Gabarito", sans-serif;
  --font-base-2: "Reddit sans", sans-serif;
  --font-size: 1rem;
  --font-weight: 400;
  --font-weight-2: 500;
  --font-color: #000;
  --font-color-secondary: #fff;
  --font-color-tertiary: #000;
  --color-golden: #DCCA87;
  --color-golden-2: #e1d4a3;
  --color-text: #616161;
  --color-text-btn: #ffffff;
  --card1-gradient-color1: #f12711;
  --card1-gradient-color2: #f5af19;
  --card2-gradient-color1: #7F00FF;
  --card2-gradient-color2: #E100FF;
  --card3-gradient-color1: #3f2b96;
  --card3-gradient-color2: #a8c0ff;
  --card4-gradient-color1: #11998e;
  --card4-gradient-color2: #38ef7d;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html.lenis {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

body {
  margin: 0;
  background: #fffff0;
  max-width: 1636px;
  width: 100%;
  height: 100%;
  display: block;
}

a{
  text-decoration: none;
  color: unset;
}
