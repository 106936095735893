.Navbar {
  position: sticky;
  top: 0;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fffff0;
  transition: padding 0.7s ease, margin 0.7s ease;
  transition: background-color 0.4s ease-in-out;
  z-index: 100;
}

.Navbar__list {
  list-style: none;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.5rem; */
}

.Navbar__item {
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: padding 0.5s ease, margin 0.5s ease;
}

.Navbar__logo {
  position: relative;
  left: 4rem;
}

.Navbar__logo img {
  width: 9rem;
  padding: 0.5rem;
  transition: width 0.7s ease, padding 0.7s ease;
}

.Navbar__wrapper {
  display: flex;
  gap: 0.25rem;
}

.Navbar__link {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offers {
  margin-right: 0.5rem;
}

.Navbar__Menu {
  margin-top: 1px;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 6.025rem;
  right: 0;
  width: 100%;
  height: calc(100vh - 4rem); /* Adjust height to cover the remaining screen */
  background: #1a1e22;
  z-index: 100;
  color: #fff;
  transform: translateY(-100%); /* Initially hide the menu off-screen */
  transition: transform 0.3s ease-in-out 0s, opacity 0s ease-in-out 0.2s,
    visibility 0.2s ease-in-out 0.2s;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Menu__Content {
  margin: 0.5rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.Menu__Links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  margin: 0.5rem;
}
.Menu__hr {
  width: 60%;
  height: 1px;
  background-color: #fff;
}
.Menu__link {
  font-size: 1.2rem;
  cursor: pointer;
}
.Menu__LeftContent {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 1rem;
}
.Menu__RightContent {
  width: 50%;
  border-left: 1px solid #fff;
  padding: 2rem;
}
.Menu__Image img {
  width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}
.Menu__ListLink:hover {
  color: #f6edcc;
}
.Menu__Heading {
  font-size: 1.4rem;
}
.Menu__Heading h3 {
  font-weight: 200;
  margin: 1rem !important;
  font-style: var(--font-base-2);
  color: #dcca87;
}

.Menu__Lists {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.Menu__List {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #f5f5f5;
  flex-wrap: wrap;
  gap: 1rem;
}

.Menu__List li {
  margin: 0.5rem;
}
.Menu__List li a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickedNavbar {
  background-color: #1a1e22 !important;
  border-bottom: 1px solid #fff;
  transition: background-color 0.4s ease-in-out;
  /* transition-delay: 0.4s; */
}

.clickedNavbar .Navbar__link {
  color: #fff;
}

.menu__icon.clicked span {
  background-color: #fff;
  transition: background-color 0.4s ease-in-out;
}

.clicked + .Navbar__Menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition: transform 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0s,
    visibility 0.2s ease-in-out 0s;
}

.scrolled .Navbar__Menu {
  top: 4rem;
  height: calc(100vh - 2rem);
  transition: top 0.7s ease, height 0.7s ease;
}

.Navbar__Menu h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.Navbar__Menu p {
  line-height: 1.6;
  font-size: 1rem;
  color: #ccc;
}

.clickedNavbar.scrolled {
  background-color: #1a1e22 !important;
  border-bottom: 1px solid #fff;
  transition: background-color 0.4s ease-in-out;
  /* transition-delay: 0.4s; */
}

.scrolled {
  padding: 0;
  transition: padding 0.7s ease, margin 0.7s ease, width 0.7s ease;
  box-shadow: 0 0.5px 2px 0 hsla(0, 0%, 0%, 0.2);
}

.scrolled .Navbar__item {
  padding: 0 0.8rem 0 0.8rem;
  transition: padding 0.7s ease, margin 0.7s ease;
}

.scrolled .Navbar__logo img {
  width: 7rem;
  padding: 0;
  transition: width 0.7s ease, padding 0.7s ease;
}

button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-family: inherit;
}

.menu__icon {
  width: 32px;
  height: 32px;
  padding: 4px;
}

.menu__icon span {
  display: block;
  width: 100%;
  height: 0.125rem;
  border-radius: 2px;
  background-color: black;
  box-shadow: 0 0.5px 2px 0 hsla(0, 0%, 0%, 0.2);
  transition: background-color 0.4s;
  position: relative;
}

.menu__icon span + span {
  margin-top: 0.375rem;
}

.menu__icon span:nth-child(1) {
  animation: ease 0.8s menu-icon-top-2 forwards;
  width: 50%;
}

.menu__icon span:nth-child(2) {
  animation: ease 0.8s menu-icon-scaled-2 forwards;
}

.menu__icon span:nth-child(3) {
  animation: ease 0.8s menu-icon-bottom-2 forwards;
  width: 75%;
}

.menu__icon:hover span {
  width: 100%;
  transition: width 0.3s ease-in-out;
}
.menu__icon.clicked span {
  width: 100%;
}
.menu__icon.clicked span:nth-child(1) {
  animation: ease 0.5s menu-icon-top forwards;
}

.menu__icon.clicked span:nth-child(2) {
  animation: ease 0.5s menu-icon-scaled forwards;
}

.menu__icon.clicked span:nth-child(3) {
  animation: ease 0.5s menu-icon-bottom forwards;
  /* background-color: rgb(255, 59, 48); */
}

@keyframes menu-icon-top {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 0.5rem;
    transform: rotate(0);
  }

  100% {
    top: 0.5rem;
    transform: rotate(45deg);
  }
}

@keyframes menu-icon-top-2 {
  0% {
    top: 0.5rem;
    transform: rotate(45deg);
  }

  50% {
    top: 0.5rem;
    transform: rotate(0);
  }

  100% {
    top: 0;
    transform: rotate(0);
  }
}

@keyframes menu-icon-bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 0.5rem;
    transform: rotate(0);
  }

  100% {
    bottom: 0.5rem;
    transform: rotate(135deg);
  }
}

@keyframes menu-icon-bottom-2 {
  0% {
    bottom: 0.5rem;
    transform: rotate(135deg);
  }

  50% {
    bottom: 0.5rem;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes menu-icon-scaled {
  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes menu-icon-scaled-2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.btn {
  display: inline-block;
  background: transparent;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  font-size: 0.9rem;
  letter-spacing: 0.3em;
  /* color: rgba(223,190,106,0.7); */
  color: black;
  border-radius: 0;
  padding: 0.8rem 1.1rem;
  transition: all 0.7s ease-out;
  background: linear-gradient(
    270deg,
    rgba(223, 190, 106, 0.8),
    rgba(146, 111, 52, 0.8),
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0)
  );
  background-position: 1% 50%;
  background-size: 300% 300%;
  text-decoration: none;
  margin: 0.625rem;
  border: none;
  /* border: 1px solid rgba(223,190,106,0.3); */
  border: 1px solid black;
}

.btn::before {
  content: "Book Now";
}

.btn:hover {
  color: #fff;
  border: 1px solid black;
  color: white;
  background-position: 100% 50%;
}

.clickedNavbar .btn {
  color: #fff;
  border: 1px solid #fff;
}

.bookingModal {
  position: fixed;
  top: 15%;
  right: -31rem;
  width: 31rem; 
  transition: right 0.3s ease-in-out;
  z-index: 100;
  cursor: auto;
}


.bookingModalShow {
  right: 0; 
}

.content__item {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}



.link {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
  /* show by default */
}


.link--dia::before,
.link--dia::after {
  opacity: 0;
  transform-origin: 50% 0%;
  transform: translate3d(0, 3px, 0);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.8, 1);
}

.link--dia:hover::before,
.link--dia:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.2, 0, 0.3, 1);
}

.link--dia::after {
  content: '';
  top: calc(100% + 4px);
  width: 70%;
  left: 15%;
}

.link--dia::before,
.link--dia:hover::after {
  transition-delay: 0.1s;
}

.link--dia:hover::before {
  transition-delay: 0s;
}

@media screen and (max-width: 932px) {
  .Navbar__logo {
    left: 2rem;
  }

  .btn::before {
    content: "BooK";
  }
  .Menu__List li a {
    margin: 0.5rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 560px) {
  .Navbar {
    padding: 0rem;
    background: antiquewhite;
  }
  .bookingModal {
    position: fixed;
    top: 4rem;
    width: 100%;
    height: 100%;
  }
  .bookingModalShow {
    right: 0; 
  }
  .Navbar__item {
    padding: 0 0.2rem 0 0.2rem;
  }
  .Navbar__Menu {
    top: 4.15rem;
    padding: 0.5rem;
  }

  .Navbar__list{
  padding: 0.5rem;}
  .Menu__Content {
    padding: 0rem;
    height: 50vh;
  }
  .Menu__Links {
    width: 80%;
  }
  .Menu__List {
    display: block;
  }
  .Menu__List li a {
    margin: 1rem;
    font-size: 1rem;
  }
  .Menu__RightContent {
    display: none;
  }
  .Menu__LeftContent {
    width: 100%;
    padding: 0.5rem;
  }

  .Navbar__logo {
    left: 0.2rem;
  }

  .Navbar__link {
    font-size: 1rem;
    padding: 0;
  }

  .Navbar__logo img {
    width: 6rem;
    padding: 0;
  }

  .btn {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 0;
  }
  /* .scrolled{
  all: initial;} */

  .scrolled {
    /* Reset styles to prevent changes for smaller screens */
    padding: 0rem !important;
    box-shadow: none !important;
    /* Add any other properties you want to reset */
  }

  .scrolled .Navbar__Menu {
    top: 4.15rem !important;
    height: calc(100vh - 2rem) !important;
  }

  /* Override specific properties for .scrolled in smaller screens if needed */
  .scrolled .Navbar__item {
    padding: 0 0.2rem 0 0.2rem !important;
  }

  .scrolled .Navbar__logo img {
    width: 6rem !important;
    padding: 0rem !important;
    /* Add any specific properties you need for smaller screens */
  }

  .offers {
    display: none;
  }
  .clickedNavbar .btn {
    color: #fff;
    border: 1px solid #fff;
  }
  .clickedNavbar {
    background-color: #1a1e22 !important;
    border-bottom: 1px solid #fff;
    transition: background-color 0.4s ease-in-out;
    /* transition-delay: 0.4s; */
  }

  .clickedNavbar.scrolled {
    background-color: #1a1e22 !important;
    border-bottom: 1px solid #fff;
    transition: background-color 0.4s ease-in-out;
    /* transition-delay: 0.4s; */
  }
}
