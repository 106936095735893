.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section__padding {
  padding: 4rem 6rem;
}
.section__padding2 {
  padding: 8rem 14rem;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}
